<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--title">
        {{ $t('paymentProof.modal.title') }}
      </div>
      <simple-photo-preview
        :photos="[paymentProof.photo]"
        v-if="paymentProof"
      ></simple-photo-preview>
      <div
        class="d-flex align-center justify-center mt-4"
        v-if="paymentProof && paymentProof.status === 'VERIFYING'"
      >
        <v-btn color="error" class="bottom--button" @click="reject">
          {{ $t('billing.btn.rejectPayment') }}
        </v-btn>
      </div>
      <div
        class="d-flex align-center justify-center mt-4"
        v-if="paymentProof && paymentProof.status === 'VERIFYING'"
      >
        <v-btn color="primary" class="bottom--button" @click="accept">
          {{ $t('billing.btn.acceptPayment') }}
        </v-btn>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const SimplePhotoPreview = () => import('@/components/utils/simple-photo-preview');

export default {
  name: 'modal-billing-payment',
  mixins: [HelperMixin],
  components: {
    SimplePhotoPreview,
  },
  data() {
    return {
      modalName: 'modal-billing-payment',
      paymentProof: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.billing.form.loading,
    }),
  },
  methods: {
    beforeOpen(event) {
      this.paymentProof = event.params.paymentProof;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async reject() {
      await this.$store.dispatch('billing/form/rejectPaymentProof');
      await this.$swal(
        this.$i18n.t('general.success'),
        this.$i18n.t('billing.rejectPaymentProof.successMsg'),
        'success',
      );
      this.close();
      await this.$store.dispatch('billing/getDetailInitData', this.$route.params.uuid);
    },
    async accept() {
      await this.$store.dispatch('billing/form/acceptPaymentProof');
      await this.$swal(
        this.$i18n.t('general.success'),
        this.$i18n.t('billing.acceptPaymentProof.successMsg'),
        'success',
      );
      this.close();
      await this.$store.dispatch('billing/getDetailInitData', this.$route.params.uuid);
    },
  },
};
</script>

<style scoped></style>
